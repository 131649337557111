<template>
        <div class="search-form p-1 pb-3">
            <b-row class="span-header">
                1. {{ $t('daily_summary.choose_time') }}
            </b-row>
            <span class="pl-3 hint-text">{{ $t('contract_reports.trips_hint')}}</span>
            <b-row class="p-1">
                <div class="col-sm-6 nopads  pl-1 pr-1">
                    <b-form-group
                        class="nopads"
                        label-for="fromDate"
                    >
                        <span class="span-title">{{ $t('trip_list.road_sec_from') }} - {{
                                $t('daily_summary.date')
                            }}</span>
                        <b-form-input
                            id="fromDate"
                            v-model="fromDate"
                            type="date"
                            size="sm"
                            @blur="dateChanged"
                        />
                    </b-form-group>
                </div>
                <div class="col-sm-6 nopads pl-2 pr-1">
                    <b-form-group
                        class="nopads"
                        label-for="toDate"
                    >
                        <span class="span-title">{{ $t('trip_list.road_sec_to') }} - {{
                                $t('daily_summary.date')
                            }}</span>
                        <b-form-input
                            id="date"
                            v-model="toDate"
                            type="date"
                            size="sm"
                            @blur="dateChanged"
                        />
                    </b-form-group>
                </div>
            </b-row>
            <b-row class="span-header pt-2">
                2. {{ $t('daily_summary.choose_contract') }}
            </b-row>
            <b-row class="nopads pr-1 pl-1 mr-1">
                <div class="col-sm-3 pl-2 pr-1">
                    <contract-contractors-filter
                        :contractIds="contractIds"
                        @contractorSet="setContractor"/>
                </div>
                <div class="col-6 nopads">
                    <open-contract-filter-multi-select
                        :to="paramToDate"
                        :from="paramFromDate"
                        :show-only-my-contracts-option="true"
                        :show-select-all-contracts-option="true"
                        :contract-collection="contractCollection"
                        :contract-disabled="!(contractNumber === '')"
                        :pre-selected-contract="preSelectedContract"
                        @loading="loading=true"
                        @loadingCompleted="loading=false"
                        @companySelected="setCompany"
                        @contractsSelected="setContracts"
                        @contractsFetched="setContractCollections"
                    />
                </div>
                <div class="col-3 nopads pl-2">
                    <span class="span-title">{{ $t('daily_summary.contract_number') }}</span>
                    <b-form-input
                        id="contractNumber"
                        v-model="contractNumber"
                        type="text"
                        size="sm"
                    />
                </div>
            </b-row>
            <b-row class="nopads pr-2 pl-2 pb-2">
                <div class="col-sm-4 nopads pr-1">
                    <span class="span-title">{{ $t('observations.contract_collection') }}</span>
                    <b-form-group
                        class="title nopads"
                        label-for="contractCollection"
                    >
                        <b-form-select
                            id="contractCollection"
                            v-model="contractCollection"
                            :disabled="!contractCollectionOptions || contractCollectionOptions.length === 0"
                            size="sm"
                        >
                            <template slot="first">
                                <option :value="null">
                                    {{ $t('trip_list.search_all') }}
                                </option>
                                <option
                                    v-for="item in contractCollectionOptions"
                                    :key="item.id"
                                    :value="item"
                                >
                                    {{ item.name }}
                                </option>
                            </template>
                        </b-form-select>

                    </b-form-group>
                </div>
                <!-- Constractor/subcontractor-->
                <div class="col-sm-4 pl-1 pr-1">
                    <contract-executors-filter
                        :contractIds="contractIds"
                        @executorSet="setExecutor"/>
                </div>
                <!-- Order-->
                <div class="col-sm-4 pl-1 pr-2">
                    <contract-order-multiselect
                        :contractIds="contractIds"
                        @ordersSelected="setOrders"
                    />
                </div>
            </b-row>
            <b-row class="nopads pr-2 pl-1 pb-2">
                <!-- Pricingmodel -->
                <div class="col-sm-6 pl-1 pr-1">
                    <pricing-model-filter
                        v-model="pricingModel"
                    />
                </div>
                <!-- Invoice state -->
                <div class="col-sm-6 pl-1 pr-1">
                    <invoice-state-filter
                        v-model="invoiceState"
                        :invoice-state-options="invoiceStateOptions"
                    />
                </div>
            </b-row>
            <div class="col-sm-12 button-container">
                <b-button
                    variant="primary"
                    class="form-button"
                    :disabled="loading || (contractIds.length < 1 && !company && !contractNumber && !contractor)"
                    @click.stop="emitSearch"
                >
                    {{ $t('common.search') }}
                </b-button>
            </div>
        <div
            v-if="loading"
            id="loader"
            class="spinner"
        />
    </div>
</template>

<script>
import {timeUtils} from '../mixins/TimeUtils'
import {restApi} from '../mixins/RestApiMixin'
import {orderMixin} from "../mixins/OrderMixin";
import {contractHelper} from "@/components/mixins/ContractMixin";
import ContractContractorsFilter from "@/components/contract/ContractContractorsFilter";
import ContractExecutorsFilter from "../contract/ContractExecutorsFilter";
import ContractOrderMultiselect from "../order/ContractOrderMultiselect";
import PricingModelFilter from "../pricingmodels/PricingModelFilter";
import InvoiceStateFilter from "../invoice/InvoiceStateFilter";
import OpenContractFilterMultiSelect from "../contract/OpenContractFilterMultiSelect";

export default {
    name: 'contract-trip-summary-filter',
    components: {
        OpenContractFilterMultiSelect, ContractContractorsFilter,
        PricingModelFilter, ContractOrderMultiselect, ContractExecutorsFilter, InvoiceStateFilter},
    mixins: [timeUtils, restApi, orderMixin, contractHelper],
    props: {
        user: {
            type: Object,
            default: null
        },
        invoiceStateOptions: {
            type: Array,
            default() {
                return []
            }
        },
        preSelectedContract: {
            type: Number,
            default: null
        }
    },
    data: function () {
        return {
            loading: false,
            fromDate: null,
            toDate: null,
            paramFromDate: null,
            paramToDate: null,
            pricingModel: null,
            contractNumber: '',
            invoiceState: null,
            company: null,
            contracts: [],
            executor: null,
            contractor: null,
            contractIds: [],
            order: null,
            contractCollection: null,
            contractCollectionOptions: [],
            orders: [],
            searchFrom: null,
            searchTo: null,
            companies: []
        }
    },
    mounted: function () {
        this.fromDate = this.getYesterday()
        this.toDate = this.getYesterday()
        this.paramFromDate = this.fromDate
        this.paramToDate = this.toDate
    },
    methods: {
        emitSearch: function () {
            let orderParams = []
            if (this.orders) {
                this.orders.forEach(order => orderParams.push(order.id))
            }
            if ((this.contractIds.length > 0 || this.company || this.contractor || this.contractNumber) && this.toDate && this.fromDate) {
                this.$emit('search', {
                        to: this.toDate,
                        from: this.fromDate,
                        contractIds: this.contractIds && this.contractIds.length > 0 ? this.contractIds : undefined,
                        executor: this.executor ? this.executor.id : undefined,
                        contractor: this.contractor ? this.contractor.id : undefined,
                        orders: orderParams.length > 0 ? orderParams : undefined,
                        customer: this.company ? this.company.id : undefined,
                        contractNumber: !(this.contractNumber === '') ? this.contractNumber : undefined,
                        pricingModel: this.pricingModel ? this.pricingModel.id : undefined,
                        invoiceState: this.invoiceState && this.invoiceState.value >= 0 ? this.invoiceState.value : undefined,
                        orderDetails: 1
                    },
                    this.company ? this.company.name : undefined,
                    this.executor ? this.executor.name : undefined,
                    this.contracts ? this.getSelectedAreaGroupNames() : undefined,
                    this.contracts ? this.getSelectedContractNames() : undefined,
                    this.orders ? this.getSelectedOrderNames() : undefined,
                )
                this.searchFrom = this.fromDate
                this.searchTo = this.toDate
            }
        },
        getYesterday: function () {
            let d = new Date();
            d.setDate(d.getDate() - 1)
            let month = d.getMonth() + 1
            if (month < 10) {
                month = '0' + month;
            }
            let day = d.getDate()
            if (day < 10) {
                day = '0' + day;
            }
            return d.getFullYear() + '-' + month + '-' + day
        },
        setContracts: function (contracts) {
            this.contracts = contracts
            this.contractIds = []
            contracts.map(contract => {
                this.contractIds.push(contract.id)
            })
        },
        setOrders(orders) {
            this.orders = orders
        },
        setCompany: function (company) {
            this.company = company
        },
        setExecutor: function (executor) {
            this.executor = executor
        },
        setContractor: function (contractor) {
            this.contractor = contractor
        },
        dateChanged: function () {
            this.paramFromDate = this.fromDate
            this.paramToDate = this.toDate
        },
        getSelectedContractNames: function () {
            let text = ''
            this.contracts.forEach(contract => {
                if (text.length > 0) {
                    text += ', '
                }
                text += contract.name
            })
            return text
        },
        getSelectedOrderNames: function () {
            let text = ''
            this.orders.forEach(order => {
                if (text.length > 0) {
                    text += ', '
                }
                text += this.getOrderAndExecutorStr(order)
            })
            return text
        },
        getSelectedAreaGroupNames: function () {
            let text = ''
            this.contracts.forEach(contract => {
                if (text.length > 0) {
                    text += ', '
                }
                text += contract.area_group ? contract.area_group.name : ''
            })
            return text
        }
    }
}
</script>
